import { navigate } from "@reach/router"

import React, { useCallback } from "react"

import { BoxColumn, FooterLink } from "../styles/FooterStandard.styled"

const FooterLinks = ({ links }) => {
  const handleDocsLink = useCallback((url) => {
    navigate(url)
  }, [])

  return links?.map((item) => (
    <BoxColumn key={item?.internalName}>
      <h4 className="link-title">{item?.label}</h4>
      {item?.menuItem?.map((link) => (
        <FooterLink
          key={link?.label}
          href={link?.url?.includes("docs.clickatell.com") ? "#" : link?.url}
          onClick={() =>
            link?.url?.includes("docs.clickatell.com")
              ? handleDocsLink(link.url)
              : undefined
          }
        >
          {link?.label}
        </FooterLink>
      ))}
    </BoxColumn>
  ))
}

export default FooterLinks
